@tailwind base;
@tailwind components;
@tailwind utilities;


.modal {
    /* background-color: #111827; Dark background for the modal */
    background-color: white;
    padding: 20px;
    border-radius: 10px; /* Slightly rounded corners */
    width: 20%; /* 20% width */
    height: 30%; /* 30% height */
    display: flex; /* Use flex for centered content */
    flex-direction: column; /* Column layout */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    position: relative; /* Relative positioning for internal content */
    margin: auto; /* Center horizontally */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5); /* Attractive shadow */
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.8); /* Darker overlay */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Ensure the overlay is on top */
    display: flex; /* Center the modal on the screen */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  /* Styles for header inside the modal */
  .modal-header {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Styles for buttons */
  .modal-button {
    background-color: #f39c12; /* Attractive button color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-button:hover {
    background-color: #e67e22; /* Darker shade on hover */
  }
  
  /* Example styles for content in AddCoinModal */
  .add-coin-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  
  .add-coin-input {
    margin: 10px 0;
    padding: 8px;
    border: none;
    border-radius: 5px;
    width: 80%; /* Full width */
  }

  .button-container {
    display: flex; /* Flexbox for button alignment */
    justify-content: center; /* Center buttons */
    gap: 20px; /* Space between buttons */
    margin-top: 20px; /* Add space above buttons */
  }
  
  .modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white; /* White text for all buttons */
  }
  
  /* Green button for Update */
  .update-button {
    background-color: #28a745; /* Green background */
  }
  
  .update-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  /* Red button for Cancel */
  .cancel-button {
    background-color: #dc3545; /* Red background */
  }
  
  .cancel-button:hover {
    background-color: #c82333; /* Darker red on hover */
  }
